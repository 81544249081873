/*
__Seed builder__
  (Read_only) Builder helper
*/
body {
  color: rgb(112, 112, 112) !important;
}

.title {
  font-family: Gotham, Verdana;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
  color: #303131;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 64px;
  padding: none;
  background-color: white;
}
.header .titleContainer {
  display: flex;
  border: none;
  height: 100%;
}
.header .titleContainer .title {
  margin: auto;
}

.heighthundred {
  height: 100%;
}

.module {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgb(240, 243, 244);
  min-width: 960px;
  min-height: 400px;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.verticalBox {
  display: flex;
  flex-flow: row;
  width: 100%;
}

.verticalBox .strech {
  flex: 1 1 auto;
}

.verticalBox .fixed {
  flex: 0 1 auto;
}

.input {
  width: 100%;
}

.box .row.head {
  flex: 0 1 auto;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .row.content {
  flex: 1 1 auto;
}

.box .row.footer {
  flex: 0 1 40px;
}

.input {
  border: none;
  background-color: rgb(230, 235, 237);
  color: #303131;
  height: 40px;
  padding: 0px 4px;
  margin-right: 12px;
  border-radius: 4px;
  width: 180px;
}

.lbl {
  margin-right: 12px;
}

.options {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.titleContainer {
  margin-left: 10px;
}
.titleContainer span {
  color: #aaa;
  font-size: 14px;
}

.input {
  background: white;
  border: 1px solid #ddd;
  height: 36px;
}

.moreFilters {
  background: white;
  border: 1px solid #ddd;
  height: 36px;
  padding: 5px 10px;
  width: auto;
  margin-right: 17px;
  border-radius: 3px;
  cursor: pointer;
}

.filterAdvIcon {
  color: #a0a0a0;
  margin-right: 4px;
}

.button button {
  height: 36px;
}

.button i {
  display: inline-block;
  font-size: 0.9em;
  padding-right: 5px;
}

.content {
  padding: 12px 24px 4px 24px;
}

.legend {
  position: relative;
  font-size: 15px;
  width: 200px;
  left: 50%;
  top: -55%;
  margin-left: -100px;
  margin-top: -70px;
  text-align: center;
  color: #20b040;
}
.legend i {
  font-size: 35px;
  margin-bottom: 10px;
  color: #20b040;
}
.legend span {
  display: inline-block;
  margin-top: 7px;
  font-style: italic;
  font-size: 1em;
}

.legend:hover {
  color: #20a040;
}
.legend:hover i {
  color: #20a040;
}

.pages {
  border-top: 1px solid #ddd;
  padding: 4px 20px 2px 20px;
  margin: 10px 5px 5px 5px;
  text-align: center;
}
.pages .page {
  display: inline-block;
  border: 1px solid #ddd;
  padding: 3px 10px;
  margin: 5px 4px;
  cursor: pointer;
}

