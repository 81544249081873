@import "App.module.scss";

.options {
  @include flex(row, flex-end);
}

.titleContainer {
  margin-left: 10px;
  .title {
    font-size: 15.5px;
    color: $primary-color;
  }
}

.input {
  background: white;
  border: 1px solid #d0d0d0;
  height: 36px;
  font-size: 15.5px;
}

.moreFilters {
  background: white;
  border: 1px solid #d0d0d0;
  height: 36px;
  padding: 5px 10px;
  width: auto;
  margin-right: 17px;
  font-size: 15.5px;
  border-radius: 3px;
  cursor: pointer;
}

.filterAdvIcon {
  color: #a0a0a0;
  margin-right: 4px;
}

.button .btn {
  font-size: 15.5px;
  height: 36px;
  padding: .3rem .75rem;
  i {
    display: inline-block;
    font-size: 0.9em;
    padding-right: 7px;
  }
}

.content {
  padding: 12px 24px 4px 24px;
}

.legend {
  position: relative;
  font-size: 14px;
  width: 230px;
  left: 50%;
  top: -55%;
  margin-left: -110px;
  margin-top: -70px;
  text-align: center;
  color: #20b040;

  i {
    font-size: 36px;
    margin-bottom: 10px;
    color: #20b040;
  }

  span {
    display: inline-block;
    margin-top: 7px;
    font-style: italic;
    font-size: 1em;
  }
}

.legend:hover {
  color: #20a040;

  i {
    color: #20a040;
  }
}

.pages {
  border-top: 1px solid #ddd;
  padding: 4px 20px 2px 20px;
  margin: 10px 5px 5px 5px;
  text-align: center;

  .page {
    display: inline-block;
    border: 1px solid #ddd;
    padding: 3px 10px;
    margin: 5px 4px;
    cursor: pointer;
  }
}