@import '_base';

.module {
  width: 100%;
  height: 100%;
}

.background {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: $primary-color;
}

.dots{
  position: fixed;
  top: 20%;
  left: 20px;
  width: 190px;
  height: 65%;
  $bg-color: $primary-color;
  $dot-size: 3px;
  $dot-space: 26px;

	background:
		linear-gradient(90deg, $bg-color ($dot-space - $dot-size), transparent 1%) center,
		linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
		$dot-color;
  background-size: $dot-space $dot-space;
}

@media (max-width: 720px) {
  .dots{
    display: none;
  }
}

.container {
  position: fixed;
  width: 430px;
  height: auto;
  padding: 50px 40px 40px 40px;
  @include card-design();
  @include align-horizontal(430px);
  @include align-vertical(475px);
  @include box-sizing(border-box);
}

.logo {
  position: fixed;
  display: inline-block;
  height: 45px;
  width: auto;
  top: 32px;
  left: 33px;
}

.crz {
  position: fixed;
  display: inline-block;
  height: 34px;
  width: auto;
  top: 82px;
  left: 35px;
}

.title {
  width: 100%;
  font-size: 24px;
  margin-bottom: 10px;
  color: $primary-color;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

.emailLbl,
.passwordLbl {
  font-size: 13px;
  margin-top: 12px;
  margin-bottom: 2px;
  text-transform: uppercase;
  color: #777;
}

.passwordLbl {
  span {
    text-transform: none;
  }
}

.email,
.password {
  width: 100%;
  font-size: 15px;
  padding: 4px 8px;
  border: 1px solid #d0d0d0;
}

.securityToken {
  width: 100%;
  font-size: 15px;
  font-family: monospace;
  letter-spacing: 0.1em;
  margin-top: 7px;
  margin-bottom: 5px;
  border: 1px solid #d0d0d0;
  resize: none;
}

.info {
  margin-left: 7px;
  font-size: 1.27em;
  color: #0080D0;
}

.error {
  width: 100%;
  margin-top: 5px;
  background-color: #ffeeee;
  color: #a05050;
  font-size: 14px;
  padding: 10px;
  font-weight: bold;
  @include box-sizing(border-box);
}

.submit {
  margin-top: 10px;
  width: 100%;
  background: $accent-color;
  color: #fff;
  border: none;
  padding: 11px 5px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  @include border-radius(5px)
}

.registry {
  position: fixed;
  display: inline-block;
  text-align: center;
  color: #fff;
  top: 50%;
  margin-top: 225px;
  width: 430px;
  left: 50%;
  padding: 11px 5px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  background: rgba(255, 255, 255, 0.1);
  @include align-horizontal(430px);
  @include border-radius(5px);

  &:hover {
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
  }
}


.footer {
  position: fixed;
  display: block;
  bottom: 0px;
  width: 420px;
  font-family: Arial;
  font-size: 13px;
  text-align: center;
  color: rgba(255, 255, 255, 0.4);
  @include align-horizontal(420px);
}

.recover {
  display: inline-block;
  margin-top: 7px;
  font-size: 14px;
  color: #a0a0a0;
  width: 100%;
  text-align: right;
}

.footer a {
  color: rgba(255, 255, 255, 0.4);

  &:hover {
    color: #fff;
  }
}

@media (max-width: 720px) {
  .logo {
    height: 30px;
    left: 14px;
    top: 20px;
  }

  .crz {
    height: 30px;
    top: 58px;
    left: 17px;
  }

  .container {
    width: 320px;
    padding: 40px 30px 30px 30px;
    @include align-horizontal(320px);
    @include align-vertical(445px);
  }

  .registry {
    width: 320px;
    margin-top: 195px;
    @include align-horizontal(320px);
  }

  .footer {
    width: 320px;
    @include align-horizontal(320px);
  }

}