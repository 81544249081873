@import "App.module.scss";

.container {
  padding: 0px 48px 0px 22px;
  height: 62px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
  color: white;
  background-color: $primary-color;
  border-bottom: 1px solid $primary-color-dark;
}

.dots{
  width: 36px;
  height: 42px;
  margin-left: 15px;
  $bg-color: $primary-color;
  $dot-size: 2px;
  $dot-space: 8px;

	background:
		linear-gradient(90deg, $bg-color ($dot-space - $dot-size), transparent 1%) center,
		linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
		$dot-color;
  background-size: $dot-space $dot-space;
}

.searchBar {
  border: none;
  color: $neutral-color-dark;
  height: 32px;
  width: 330px;
  margin-top: 4px;
  box-sizing: border-box;
  margin-right: 20px;

  div,
  input {
    height: 32px;
    border-right: none;

    &::placeholder {
      color: #a0a0a0;
      opacity: 1;
    }
    
    &:-ms-input-placeholder {
      color: #a0a0a0;
    }
    
    &::-ms-input-placeholder {
      color: #a0a0a0;
    }
  }

  .normalizeAppend {
    background-color: white;
    border-left: none;
    color: $gray-color;
  }
}

.info {
  a {
    display: inline-block;
    margin: 2px 7px 0px 5px;
    color: rgba(255, 255, 255, 0.85);
    font-size: 25px;

    &:hover {
      color: white;
    }
  }
}

.logoContainer {
  height: 52px;
  padding-right: 24px;
  margin-left: 12px;

  .logo {
    display: inline-block;
    height: 24px;
    margin-top: 10px;
    left: 40px;
  }

  .crz{
    display: inline-block;
    height: 30px;
    margin-top: 5px;
    left: 40px;
  }
}


.titleContainer {
  border-left: 1px solid #ffffff40;
  height: 42px;
  text-align: center;
  font-weight: 100;
  font-size: 17.5px;
  color: white;
  line-height: 43px;
  padding-left: 24px;
  margin-left: 12px;
  cursor: default;
}

.flexBoxContainer {
  height: 48px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.right {
  display: flex;
  flex-direction: row;
}

.left {
  display: flex;
  flex-direction: row;
}

.notifications {
  font-size: 16px;
  height: 40px;
  width: 40px;
  background-color: rgba(6, 80, 87, 0.6);
  color: $lightblue-color;
  border-radius: 4px;
  border: none;

  &:hover {
    background-color: $primary-color-dark;
    color: $lightblue-color;
  }
}

.profile {
  margin-left: 6px;
  height: 40px;
  border-radius: 4px;
  padding: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: $primary-color-dark;
    color: $lightblue-color;
    cursor: pointer;
  }

  .username {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 0.9rem;

    .name {
      font-size: 0.9rem;
    }

    .type {
      font-size: 0.8rem;
    }
  }

  .image {
    margin-right: 10px;
    height: 32px;
    width: 32px;
    border-radius: 16px;
    background-color: $lightblue-color;
    background-size: cover;
    background-repeat: no-repeat;
    box-sizing: border-box;
    border: 1px solid $lightblue-color;
  }
}

@media (max-width: 720px) {
  .titleContainer {
    display: none;
  }
}