/*
__Seed builder__
  (Read_only) Builder helper
*/
body {
  color: rgb(112, 112, 112) !important;
}

.title {
  font-family: Gotham, Verdana;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
  color: #303131;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 64px;
  padding: none;
  background-color: white;
}
.header .titleContainer {
  display: flex;
  border: none;
  height: 100%;
}
.header .titleContainer .title {
  margin: auto;
}

.heighthundred {
  height: 100%;
}

.module {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgb(240, 243, 244);
  min-width: 960px;
  min-height: 400px;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.verticalBox {
  display: flex;
  flex-flow: row;
  width: 100%;
}

.verticalBox .strech {
  flex: 1 1 auto;
}

.verticalBox .fixed {
  flex: 0 1 auto;
}

.input {
  width: 100%;
}

.box .row.head {
  flex: 0 1 auto;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .row.content {
  flex: 1 1 auto;
}

.box .row.footer {
  flex: 0 1 40px;
}

.input {
  border: none;
  background-color: rgb(230, 235, 237);
  color: #303131;
  height: 40px;
  padding: 0px 4px;
  margin-right: 12px;
  border-radius: 4px;
  width: 180px;
}

.lbl {
  margin-right: 12px;
}

.paper {
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 21px;
}

.header.mainHeader {
  height: 50px;
  border-bottom: 1px solid rgb(230, 235, 237);
}
.header.mainHeader .closeButton {
  border: none;
  background-color: none;
  padding: 4px;
  border-radius: 4px;
  width: 32px;
}
.header.mainHeader .closeButton:hover {
  background-color: rgb(239, 71, 111);
  color: white;
}

.modalTitle {
  color: #303131;
}

.module h5 {
  text-transform: uppercase;
  font-size: 20px;
}

.row.header.alert {
  height: 76px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  background-color: rgba(255, 183, 77, 0.1);
  color: rgb(112, 112, 112);
  padding: 8px;
}
.row.header.alert .item {
  margin: 2px 8px;
}
.row.header.alert .subtitle {
  font-weight: bold;
}

.row.header.details {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.row.header.details .titleContainer {
  display: block;
  height: 30px;
  margin: 16px 0px;
  margin-bottom: 0px;
}
.row.header.details table tr td:first-child {
  width: 240px;
}
.row.header.details td {
  padding: 4px 0px;
}
.row.header.details table,
.row.header.details tr {
  border: none;
  padding: 0px 0px;
  margin: 0px 0px;
}

.header.flexContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

