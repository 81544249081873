@import "App.module.scss";

.paper {
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 21px;
}

.header.mainHeader {
  height: 50px;
  border-bottom: 1px solid $lightblue-color;

  .closeButton {
    border: none;
    background-color: none;
    padding: 4px;
    border-radius: 4px;
    width: 32px;

    &:hover {
      background-color: $danger-color;
      color: white;
    }
  }
}

.modalTitle {
  color: $neutral-color-dark;
}

.module h5 {
  text-transform: uppercase;
  font-size: 20px;
}

.row.header.alert {
  height: 76px;
  @include flex(column, start);
  background-color: $warning-color-bkg;
  color: $neutral-color;
  padding: 8px;

  .item {
    margin: 2px 8px;
  }

  .subtitle {
    font-weight: bold;
  }
}

.row.header.details {
  height: 200px;

  .titleContainer {
    display: block;
    height: 30px;
    margin: 16px 0px;
    margin-bottom: 0px;
  }

  @include flex(column, start);

  table tr td {
    &:first-child {
      width: 240px;
    }
  }

  td {
    padding: 4px 0px;
  }

  table,
  tr {
    border: none;
    padding: 0px 0px;
    margin: 0px 0px;
  }
}

.header.flexContainer {
  @include flex(row, space-between);
  align-items: center;
}