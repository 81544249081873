/*
__Seed builder__
  (Read_only) Component helper
*/

@import "_mixins";

.module {
  position: fixed;
  z-index: 1300;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.5);
}

.container {
  position: relative;
  top: 50%;
  left: 50%;
}

.close {
  position: absolute;
  color: rgba(255, 255, 255, 0.6);
  width: 35px;
  height: 35px;
  margin-top: -24px;
  border: none;
  background: rgba(255, 255, 255, 0.3);
  @include border-radius(50%);
}

.content {
  width: 100%;
  height: 100%;
}