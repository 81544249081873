@import '_base';

.module {
  width: 100%;
  height: 100%;
}

.module select {
  background: white;
  border: 1px solid #d0d0d0;
  width: 300px;
  height: 35px;
}

.notice {
  padding: 10px;
  border: 1px solid #d0d0d0;
  font-size: 15px;
  margin-bottom: 20px;

  a {
    color: #1D5157;
  }
}

.lab{
  display: inline-block;
  width: 60px;
}

.date{
  display: inline-block;
  border: 1px solid #d0d0d0;
  margin-top: 10px;
  padding: 4px;
  width: 185px;
  cursor: pointer;
}

.jumbotron {
  background-color: $primary-color;
  color: white;
  margin: 0px;
  padding: 15px 20px;

  h4 {
    font-size: 21px;
    margin-bottom: 0px;
  }
}

.form {
  background: #fff;
  max-height: 450px;
  overflow: auto;
  padding: 24px 32px 30px 32px;
}

.lbl {
  margin-bottom: 10px;
}

.loading {
  width: 100%;
  height: 100%;
  padding-left: 50px;
  background: #fff;

  .status {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 40px;
    margin-left: -50px;
    text-align: center;
  }
}

.newDoc {
  padding: 3px 12px;
  margin: 0px;
}

.fil {
  display: inline;
  width: 350px;
}

.remove {
  float: right;
  margin-top: -25px;
}


.btn {
  padding: 6px 17px;
  border-radius: 4px;
  color:$primary-color;
  margin-bottom: 0;

  &:hover {
    text-decoration: none;
    color: $dark-color;
  }
}

.btnRegular {
  background-color: #E6EBED;
  border: 1px solid #D5DBDA;

  &:hover {
    background-color: #CFD3D5;
    border: 1px solid #CFD3D5;
  }

  &:active {
    background-color: #B8BCBE;
    border: 1px solid #B8BCBE;
  }

  &:disabled,
  &[disabled] {
    background-color: #F0F3F4;
    border: 1px solid #F0F3F4;
    color: #9CA1A4;
    cursor: not-allowed;
  }
}

.btnOutline {
  background-color: none;
  border: 1px solid 1D5157;

  &:hover {
    background-color: 1D5157;
    border: 1px solid 1D5157;
    color: white;
  }

  &:active {
    background-color: #102F41;
    border: 1px solid #102F41;
    color: #CFD5D9;
  }

  &:disabled,
  &[disabled] {
    background-color: #8A9DA8;
    border: 1px solid #8A9DA8;
    color: white;
    cursor: not-allowed;
  }
}

.btnGreen {
  border: 1px solid $primary-color;
  background-color: $primary-color;
  color: white;

  &:hover {
    background-color: $dark-color;
    color: white;
  }

  &:active {
    background-color: $primary-color;
    border-color: $primary-color;
  }

  .text {
    color: white;
  }

  .icon {
    font-size: 20px;

    path {
      color: white;
    }
  }
}