

@import "_base";

$primary-color-dark: $dark-color;
$info-color: rgba(27, 154, 170, 1);
$success-color: rgba(27, 180, 148, 1);
$success-color-bkg: rgba(27, 180, 148, 0.1);
$danger-color: rgba(239, 71, 111, 1);
$danger-color-bkg: rgba(239, 71, 111, 0.1);
$warning-color: rgba(255, 183, 77, 1);
$warning-color-bkg: rgba(255, 183, 77, 0.1);
$neutral-color: rgb(112, 112, 112);
$gray-color: rgb(156, 161, 164);
$light-color: rgb(240, 243, 244);
$lightblue-color: rgb(230, 235, 237);
$neutral-color-dark: #303131;

$padding-lateral: 48px;

body {
  color: $neutral-color !important;
}

@mixin font {
  font-family: Gotham, Verdana;
}

@mixin flex($direction, $justify) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
}

.title {
  @include font();
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
  color: $neutral-color-dark;
}

.header {
  width: 100%;
  @include flex(row, space-between);
  height: 64px;
  padding: none;
  background-color: white;
  .titleContainer {
    display: flex;
    border: none;
    height: 100%;
    .title {
      margin: auto;
    }
  }
}

.heighthundred {
  height: 100%;
}

.module {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: $light-color;
  min-width: 960px;
  min-height: 400px;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.verticalBox {
  display: flex;
  flex-flow: row;
  width: 100%;
}

.verticalBox .strech {
  flex: 1 1 auto;
}

.verticalBox .fixed {
  flex: 0 1 auto;
}

.input {
  width: 100%;
}

.box .row.head {
  flex: 0 1 auto;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .row.content {
  flex: 1 1 auto;
}

.box .row.footer {
  flex: 0 1 40px;
}

.input {
  border: none;
  background-color: $lightblue-color;
  color: $neutral-color-dark;
  height: 40px;
  padding: 0px 4px;
  margin-right: 12px;
  border-radius: 4px;
  width: 180px;
}

.lbl {
  margin-right: 12px;
}
