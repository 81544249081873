@import '_base';

.module {
  width: 100%;
  height: 100%;
}

.background {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: $primary-color;
}

.container {
  position: fixed;
  width: 425px;
  height: auto;
  padding: 50px 36px 40px 36px;
  @include card-design();
  @include align-horizontal(425px);
  @include align-vertical(400px);
  @include box-sizing(border-box);

  li {
    margin-top: 5px;
    margin-bottom: 10px;

    b {
      color: #19454E;
    }
  }
}

.dots{
  position: fixed;
  top: 20%;
  left: 20px;
  width: 190px;
  height: 65%;
  $bg-color: $primary-color;
  $dot-size: 3px;
  $dot-space: 26px;

	background:
		linear-gradient(90deg, $bg-color ($dot-space - $dot-size), transparent 1%) center,
		linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
		$dot-color;
  background-size: $dot-space $dot-space;
}

@media (max-width: 720px) {
  .dots{
    display: none;
  }
}

.logo {
  position: fixed;
  display: inline-block;
  height: 45px;
  width: auto;
  top: 32px;
  left: 33px;
}

.crz {
  position: fixed;
  display: inline-block;
  height: 34px;
  width: auto;
  top: 82px;
  left: 35px;
}

.title {
  width: 100%;
  font-size: 23px;
  margin-bottom: 10px;
  color: $primary-color;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

.subtitle {
  color: #808080;
  margin-top: 14px;
  margin-bottom: 10px;
  font-size: 16px;
  text-align: left;

  i {
    color: #999;
    font-size: 14px;
    display: inline-block;
    padding: 4px 0px;
  }
}

.warning {
  border: 1px solid #fafafa;
  background: #fafafa;
  color: $primary-color;
  border: 1px solid #e0e0e0;
  padding: 12px;
  font-size: 15px;
}

.submit {
  margin-top: 21px;
  width: 100%;
  background: $accent-color;
  color: #fff;
  border: none;
  padding: 11px 5px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  @include border-radius(5px)
}

.error {
  width: 100%;
  margin-top: 5px;
  background-color: #ffeeee;
  color: #a05050;
  font-size: 14px;
  padding: 10px;
  font-weight: bold;
  @include box-sizing(border-box);
}

.login {
  display: inline-block;
  margin-top: 10px;
  width: 100%;
  border: 1px solid #a0a0a0;
  padding: 7px 10px;
  color: #777;
  text-align: center;
  @include border-radius(5px);

  &:hover {
    background: #f0f0f0;
    text-decoration: none;
  }
}

.footer {
  position: fixed;
  display: block;
  bottom: 0px;
  left: 50%;
  width: 420px;
  font-family: Arial;
  font-size: 13px;
  text-align: center;
  margin-left: -210px;
  color: rgba(255, 255, 255, 0.4);
}

.footer a {
  color: rgba(255, 255, 255, 0.4);

  &:hover {
    color: #fff;
  }
}


@media (max-width: 720px) {
  .logo {
    height: 30px;
    left: 14px;
    top: 20px;
  }

  .crz {
    height: 30px;
    top: 58px;
    left: 17px;
  }

  .title {
    font-size: 22px;
  }


  .container {
    width: 320px;
    padding: 40px 30px 30px 30px;
    @include align-horizontal(320px);
    @include align-vertical(460px);
  }

  .registry {
    width: 320px;
    margin-top: 180px;
    @include align-horizontal(320px);
  }

  .footer {
    width: 320px;
    @include align-horizontal(320px);
  }

}