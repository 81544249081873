/*
__Seed builder__
  (Read_only) Builder helper
*/
.module {
  width: 100%;
  height: 100%;
}

.background {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #00454d;
}

.dots {
  position: fixed;
  top: 20%;
  left: 20px;
  width: 190px;
  height: 65%;
  background: linear-gradient(90deg, #00454d 22px, transparent 1%) center, linear-gradient(#00454d 22px, transparent 1%) center, rgba(53, 227, 93, 0.6274509804);
  background-size: 26px 26px;
}

@media (max-width: 720px) {
  .dots {
    display: none;
  }
}
.container {
  position: fixed;
  width: 380px;
  height: auto;
  padding: 50px 40px 40px 40px;
  background-color: #fff;
  border: 1px solid #e7e7e7;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  left: 50%;
  margin-left: -190px;
  top: 50%;
  margin-top: -190px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.logo {
  position: fixed;
  display: inline-block;
  height: 45px;
  width: auto;
  top: 32px;
  left: 33px;
}

.crz {
  position: fixed;
  display: inline-block;
  height: 34px;
  width: auto;
  top: 82px;
  left: 35px;
}

.title {
  width: 100%;
  font-size: 24px;
  margin-bottom: 10px;
  color: #00454d;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}
.title span {
  display: block;
  font-size: 0.7em;
}

.emailLbl,
.passwordLbl {
  font-size: 13px;
  margin-top: 12px;
  margin-bottom: 2px;
  text-transform: uppercase;
  color: #777;
}

.passwordLbl span {
  text-transform: none;
}

.email,
.password {
  width: 100%;
  font-size: 15px;
  padding: 4px 8px;
  border: 1px solid #d0d0d0;
}

.email {
  margin-bottom: 15px;
}

.securityToken {
  width: 100%;
  font-size: 15px;
  font-family: monospace;
  letter-spacing: 0.1em;
  margin-top: 7px;
  margin-bottom: 5px;
  border: 1px solid #d0d0d0;
  resize: none;
}

.info {
  margin-left: 7px;
  font-size: 1.27em;
  color: #0080D0;
}

.error {
  width: 100%;
  margin-top: 5px;
  background-color: #ffeeee;
  color: #a05050;
  font-size: 14px;
  padding: 10px;
  font-weight: bold;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.submit {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
  width: 100%;
  background: #e0c010;
  color: #fff;
  border: none;
  padding: 11px 5px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}
.submit:hover {
  text-decoration: none;
  color: white;
  text-decoration: underline;
}

.registry {
  position: fixed;
  display: inline-block;
  text-align: center;
  color: #fff;
  top: 50%;
  margin-top: 200px;
  width: 430px;
  left: 50%;
  padding: 11px 5px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  background: rgba(255, 255, 255, 0.1);
  left: 50%;
  margin-left: -215px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}
.registry:hover {
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
}

.footer {
  position: fixed;
  display: block;
  bottom: 0px;
  width: 420px;
  font-family: Arial;
  font-size: 13px;
  text-align: center;
  color: rgba(255, 255, 255, 0.4);
  left: 50%;
  margin-left: -210px;
}

.recover {
  display: inline-block;
  margin-top: 7px;
  font-size: 14px;
  color: #a0a0a0;
  width: 100%;
  text-align: right;
}

.footer a {
  color: rgba(255, 255, 255, 0.4);
}
.footer a:hover {
  color: #fff;
}

@media (max-width: 720px) {
  .logo {
    height: 30px;
    left: 14px;
    top: 20px;
  }
  .crz {
    height: 30px;
    top: 58px;
    left: 17px;
  }
  .container {
    width: 320px;
    padding: 40px 30px 30px 30px;
    left: 50%;
    margin-left: -160px;
    top: 50%;
    margin-top: -222.5px;
  }
  .registry {
    width: 320px;
    margin-top: 195px;
    left: 50%;
    margin-left: -160px;
  }
  .footer {
    width: 320px;
    left: 50%;
    margin-left: -160px;
  }
}

