@import "App.module.scss";

.list {
  height: 100%;
  color: $gray-color;
}

.title {
  float: left;
  text-align: left;
  font-size: 15.5px;
  color: $primary-color;
  margin-left: 0px !important;

  i {
    color: $primary-color;
    margin-right: 14px;
  }
}

.titleContainer {
  width: 100%;
}

.activeItem {
  background-color: $lightblue-color !important;
  color: $primary-color !important;
  border: 1px solid $lightblue-color !important;
}

.alertTitle {
  color: $neutral-color-dark;
  line-height: normal;
}

.alertName {
  line-height: normal;
  font-size: 12px;
  color: $neutral-color;
}

.rowMessage {
  display: flex;
  justify-content: center;
  justify-items: center;
  text-align: center;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
}

.messageTitle {
  text-transform: uppercase;
  color: $neutral-color;
  font-size: 1.5em;
}


.happyFace {
  font-size: 4em;
  color: $gray-color;
}

.btn {
  float: right;
  border: 1px solid #d0d0d0;
  margin-top: 2px;
  height: 35px;
  padding: 5px 10px;
  color: #777;
  font-size: 15.5px;
  cursor: pointer;

  i {
    color: #808080;
    margin-right: 6px;
  }

  &:hover {
    border: 1px solid #bbb;
    color: #888;
    background: #f0f0f0;
  }
}