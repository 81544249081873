/*
__Seed builder__
  (Read_only) Builder helper
*/
.module {
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 45px;
  overflow: auto;
}

.container {
  height: 310px;
  overflow: auto;
  padding: 14px;
  background-color: #fff;
  border: 1px solid #e7e7e7;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 1.8em;
  font-size: 13px;
}
.container li {
  margin-top: 5px;
  margin-bottom: 10px;
}
.container li b {
  color: #19454E;
}

.title {
  width: 100%;
  font-size: 16px;
  margin-bottom: 8px;
  color: #00454d;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

.subtitle {
  color: #90a0a0;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 15px;
  text-transform: uppercase;
}

.checkContainer {
  padding: 14px;
  margin-top: 7px;
  color: #333;
}
.checkContainer input {
  margin-right: 10px;
}

.submit {
  margin-top: 7px;
  width: 100%;
  background: #00454d;
  color: #fff;
  border: none;
  padding: 11px 5px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}

