@import "_base";

.module {
  max-width: 100%;
  height: 100%;
  background: #fff;
}

.row {
  height: 100%;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 27px;
  padding-bottom: 27px;
}

.col {
  border-right: 1px solid #eee;
  border-left: 1px solid #eee;
}

.title {
  font-size: 16px;
  margin-bottom: 10px;
  color: $primary-color;
  text-align: center;
}

.subtitle {
  font-size: 14px;
  color: #888;
  text-align: center;
}

.image {
  font-size: 42px;
  width: 100%;
  margin-top: 20px;
  text-align: center;
  color: #20b040;

  i {
    font-size: 42px;
  }
}