/*
__Seed builder__
  (Read_only) Builder helper
*/
.module {
  width: 100%;
  height: 100%;
}

.module select {
  background: white;
  border: 1px solid #d0d0d0;
  width: 300px;
  height: 35px;
}

.notice {
  padding: 10px;
  border: 1px solid #d0d0d0;
  font-size: 15px;
  margin-bottom: 20px;
}
.notice a {
  color: #1D70A0;
}

.jumbotron {
  background-color: #00454d;
  color: white;
  margin: 0px;
  padding: 15px 20px;
}
.jumbotron h4 {
  font-size: 21px;
  margin-bottom: 0px;
}

.download {
  display: inline-block;
  padding: 2px 7px;
  color: #1080B0;
  border: 1px solid #1080B0;
  margin-bottom: 10px;
}

.form {
  background: #fff;
  max-height: 450px;
  overflow: auto;
  padding: 24px 40px 30px 40px;
}

.lbl {
  margin-bottom: 10px;
}

.loading {
  width: 100%;
  height: 100%;
  padding-left: 50px;
  padding-bottom: 20px;
  background: #fff;
}
.loading .status {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  padding-top: 15px;
  margin-top: 40px;
  margin-left: -150px;
  text-align: center;
}

.newDoc {
  padding: 3px 12px;
  margin: 0px;
}

.fil {
  display: inline-block;
  width: 350px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.remove {
  float: right;
  margin-top: -25px;
}

.btn {
  padding: 6px 17px;
  border-radius: 4px;
  color: #1D5157;
  margin-bottom: 0;
}
.btn:hover {
  text-decoration: none;
  color: #1D5157;
}

.btnRegular {
  background-color: #E6EBED;
  border: 1px solid #D5DBDA;
}
.btnRegular:hover {
  background-color: #CFD3D5;
  border: 1px solid #CFD3D5;
}
.btnRegular:active {
  background-color: #B8BCBE;
  border: 1px solid #B8BCBE;
}
.btnRegular:disabled, .btnRegular[disabled] {
  background-color: #F0F3F4;
  border: 1px solid #F0F3F4;
  color: #9CA1A4;
  cursor: not-allowed;
}

.btnOutline {
  background-color: none;
  border: 1px solid 1D5157;
}
.btnOutline:hover {
  background-color: 1D5157;
  border: 1px solid 1D5157;
  color: white;
}
.btnOutline:active {
  background-color: #102F41;
  border: 1px solid #102F41;
  color: #CFD5D9;
}
.btnOutline:disabled, .btnOutline[disabled] {
  background-color: #8A9DA8;
  border: 1px solid #8A9DA8;
  color: white;
  cursor: not-allowed;
}

.btnGreen {
  border: 1px solid #00454d;
  background-color: #00454d;
  color: white;
}
.btnGreen:hover {
  background-color: #002030;
  color: white;
}
.btnGreen:active {
  background-color: #00454d;
  border-color: #00454d;
}
.btnGreen .text {
  color: white;
}
.btnGreen .icon {
  font-size: 20px;
}
.btnGreen .icon path {
  color: white;
}

