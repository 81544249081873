@import "App.module.scss";

.module {
  position: static;
  display: inline-block;
  height: auto;
  min-height: auto;
  min-width: auto;
  padding-top: 0px;
  padding-bottom: 4px;
  margin-bottom: 8px;
  background: white;
}

.header {
  display: block;
  height: auto;
  border: none;
}

.titleContainer {
  width: 100%;
  margin: 4px 0px;
}

.title {
  text-align: left;
  margin-left: 0px !important;
  font-size: 13px;
  color: $primary-color;
}

.module select {
  width: 100%;
  padding: 9px 9px;
  background-color: #fff;
  border: 1px solid #d0d0d0;
}

.info{
  margin-left: 7px;
  font-size: 14.5px;
  color: #bbb;
}