@import "App.module.scss";

.listItem {
  padding: 17px 21px;
  background-color: white;
  color: $neutral-color;
  line-height: 2px;
  display: block;
  border-bottom: 1px solid $lightblue-color;

  &:hover {
    background-color: $lightblue-color;
    color: $neutral-color-dark;
    text-decoration: none;
    cursor: pointer;
  }

  .itemTitle {
    margin-left: 10px;
    font-weight: bold;

    span {
      font-weight: normal;
      font-size: 0.85em;
      color: #aaa;
      margin-left: 4px;
    }
  }

  .itemSubtitle {
    margin-left: 25px;
    font-size: 0.95em;
    line-height: 1.5em;
    color: #999;
  }
}

.activeListItem {
  background-color: $lightblue-color;
  color: $primary-color;
}

.listContainer {
  height: 200px;
  overflow: auto;
}

.def {
  width: 100%;
  text-align: center;
  padding: 30px;
}

.btn {
  width: 100%;
  border: 1px solid #ddd;
  color: #888;
  cursor: pointer;

  &:hover {
    border: 1px solid #bbb;
    color: #888;
    background: #f0f0f0;
  }
}