/*
__Seed builder__
  (Read_only) Builder helper
*/
body {
  color: rgb(112, 112, 112) !important;
}

.title {
  font-family: Gotham, Verdana;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
  color: #303131;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 64px;
  padding: none;
  background-color: white;
}
.header .titleContainer {
  display: flex;
  border: none;
  height: 100%;
}
.header .titleContainer .title {
  margin: auto;
}

.heighthundred {
  height: 100%;
}

.module {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgb(240, 243, 244);
  min-width: 960px;
  min-height: 400px;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.verticalBox {
  display: flex;
  flex-flow: row;
  width: 100%;
}

.verticalBox .strech {
  flex: 1 1 auto;
}

.verticalBox .fixed {
  flex: 0 1 auto;
}

.input {
  width: 100%;
}

.box .row.head {
  flex: 0 1 auto;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .row.content {
  flex: 1 1 auto;
}

.box .row.footer {
  flex: 0 1 40px;
}

.input {
  border: none;
  background-color: rgb(230, 235, 237);
  color: #303131;
  height: 40px;
  padding: 0px 4px;
  margin-right: 12px;
  border-radius: 4px;
  width: 180px;
}

.lbl {
  margin-right: 12px;
}

.listItem {
  padding: 17px 21px;
  background-color: white;
  color: rgb(112, 112, 112);
  line-height: 2px;
  display: block;
  border-bottom: 1px solid rgb(230, 235, 237);
}
.listItem:hover {
  background-color: rgb(230, 235, 237);
  color: #303131;
  text-decoration: none;
  cursor: pointer;
}
.listItem .itemTitle {
  margin-left: 10px;
  font-weight: bold;
}
.listItem .itemTitle span {
  font-weight: normal;
  font-size: 0.85em;
  color: #aaa;
  margin-left: 4px;
}
.listItem .itemSubtitle {
  margin-left: 25px;
  font-size: 0.95em;
  line-height: 1.5em;
  color: #999;
}

.activeListItem {
  background-color: rgb(230, 235, 237);
  color: #00454d;
}

.listContainer {
  height: 200px;
  overflow: auto;
}

.def {
  width: 100%;
  text-align: center;
  padding: 30px;
}

.btn {
  width: 100%;
  border: 1px solid #ddd;
  color: #888;
  cursor: pointer;
}
.btn:hover {
  border: 1px solid #bbb;
  color: #888;
  background: #f0f0f0;
}

