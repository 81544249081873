/*
__Seed builder__
  (Read_only) Builder helper
*/
body {
  color: rgb(112, 112, 112) !important;
}

.title {
  font-family: Gotham, Verdana;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
  color: #303131;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 64px;
  padding: none;
  background-color: white;
}
.header .titleContainer {
  display: flex;
  border: none;
  height: 100%;
}
.header .titleContainer .title {
  margin: auto;
}

.heighthundred {
  height: 100%;
}

.module {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgb(240, 243, 244);
  min-width: 960px;
  min-height: 400px;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.verticalBox {
  display: flex;
  flex-flow: row;
  width: 100%;
}

.verticalBox .strech {
  flex: 1 1 auto;
}

.verticalBox .fixed {
  flex: 0 1 auto;
}

.input {
  width: 100%;
}

.box .row.head {
  flex: 0 1 auto;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .row.content {
  flex: 1 1 auto;
}

.box .row.footer {
  flex: 0 1 40px;
}

.input {
  border: none;
  background-color: rgb(230, 235, 237);
  color: #303131;
  height: 40px;
  padding: 0px 4px;
  margin-right: 12px;
  border-radius: 4px;
  width: 180px;
}

.lbl {
  margin-right: 12px;
}

.module {
  position: static;
  width: 100%;
  height: 100%;
  min-width: 0px;
  min-height: 0px;
}

.box {
  width: auto;
}

.jumbotron {
  background-color: #00454d;
  color: white;
  margin: 0px;
  padding: 16px 20px;
}
.jumbotron h4 {
  font-size: 19px;
  margin-bottom: 0px;
}

.form {
  background: #fff;
  max-height: 280px;
  padding: 24px 40px 18px 40px;
}

.lbl {
  margin-bottom: 10px;
}

.loading {
  width: 100%;
  height: 100%;
  padding-left: 50px;
  background: #fff;
}
.loading .status {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 40px;
  margin-left: -50px;
  text-align: center;
}

.btn {
  padding: 5px 17px;
  border-radius: 4px;
  color: #1D5157;
  margin-bottom: 0;
  margin-top: 5px;
}
.btn:hover {
  text-decoration: none;
  color: #1D5157;
}

.error {
  width: 100%;
  margin-top: 5px;
  background-color: #ffeeee;
  color: #a05050;
  font-size: 14px;
  padding: 10px;
  font-weight: bold;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.btnGreen {
  border: 1px solid rgb(27, 180, 148);
  background-color: rgb(27, 180, 148);
  color: white;
}
.btnGreen:hover {
  background-color: #1D5157;
  color: white;
}
.btnGreen:active {
  background-color: #1D5157;
  border-color: #1D5157;
}
.btnGreen .text {
  color: white;
}
.btnGreen .icon {
  font-size: 20px;
}
.btnGreen .icon path {
  color: white;
}

.input {
  width: 100%;
  margin-bottom: 4px;
  padding: 8px 7px;
}

