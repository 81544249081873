@import '_base';

.module {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: $primary-color;
  overflow: auto;
}

.container {
  position: absolute;
  width: 400px;
  height: auto;
  padding: 50px 40px 40px 40px;
  @include card-design();
  @include align-horizontal(400px);
  @include align-vertical(440px);
  @include box-sizing(border-box);
  line-height: 1.8em;
  margin-bottom: 52px;

  li {
    margin-top: 5px;
    margin-bottom: 10px;

    b {
      color: #19454E;
    }
  }
}

.dots{
  position: fixed;
  top: 20%;
  left: 20px;
  width: 190px;
  height: 65%;
  $bg-color: $primary-color;
  $dot-size: 3px;
  $dot-space: 26px;

	background:
		linear-gradient(90deg, $bg-color ($dot-space - $dot-size), transparent 1%) center,
		linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
		$dot-color;
  background-size: $dot-space $dot-space;
}

@media (max-width: 720px) {
  .dots{
    display: none;
  }
}

.logo {
  position: fixed;
  display: inline-block;
  height: 45px;
  width: auto;
  top: 32px;
  left: 33px;
}

.crz {
  position: fixed;
  display: inline-block;
  height: 34px;
  width: auto;
  top: 82px;
  left: 35px;
}

.imgKeygen {
  display: inline-block;
  margin: 4px;
  width: 220px;
  border: 2px solid #ddd;
}

.imgSecret {
  display: inline-block;
  margin: 4px;
  width: 300px;
  border: 2px solid #ddd;
}



.title {
  width: 100%;
  font-size: 24px;
  margin-bottom: 8px;
  color: $primary-color;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

.subtitle {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 1.5em;
}

.login {
  border: 1px solid #a0a0a0;
  padding: 7px 10px;
  float: right;
  color: #777;
}

.footer {
  position: fixed;
  display: block;
  bottom: 0px;
  width: 420px;
  font-size: 14px;
  text-align: center;
  color: rgba(255, 255, 255, 0.4);
  @include align-horizontal(420px);
}

.footer a {
  color: rgba(255, 255, 255, 0.4);

  &:hover {
    color: #fff;
  }
}


@media (max-width: 720px) {
  .logo {
    height: 30px;
    left: 14px;
    top: 20px;
  }

  .crz {
    height: 30px;
    top: 58px;
    left: 17px;
  }

  .container {
    width: 320px;
    padding: 40px 30px 30px 30px;
    @include align-horizontal(320px);
    @include align-vertical(400px);
  }

  .imgSecret{
    width: 260px;
  }

  .registry {
    width: 320px;
    margin-top: 180px;
    @include align-horizontal(320px);
  }

  .footer {
    width: 320px;
    @include align-horizontal(320px);
  }

}