/*
__Seed builder__
  (Read_only) Builder helper
*/
body {
  color: rgb(112, 112, 112) !important;
}

.title {
  font-family: Gotham, Verdana;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
  color: #303131;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 64px;
  padding: none;
  background-color: white;
}
.header .titleContainer {
  display: flex;
  border: none;
  height: 100%;
}
.header .titleContainer .title {
  margin: auto;
}

.heighthundred {
  height: 100%;
}

.module {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgb(240, 243, 244);
  min-width: 960px;
  min-height: 400px;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.verticalBox {
  display: flex;
  flex-flow: row;
  width: 100%;
}

.verticalBox .strech {
  flex: 1 1 auto;
}

.verticalBox .fixed {
  flex: 0 1 auto;
}

.input {
  width: 100%;
}

.box .row.head {
  flex: 0 1 auto;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .row.content {
  flex: 1 1 auto;
}

.box .row.footer {
  flex: 0 1 40px;
}

.input {
  border: none;
  background-color: rgb(230, 235, 237);
  color: #303131;
  height: 40px;
  padding: 0px 4px;
  margin-right: 12px;
  border-radius: 4px;
  width: 180px;
}

.lbl {
  margin-right: 12px;
}

.module {
  position: static;
  width: 100%;
  height: 100%;
  min-width: 0px;
  min-height: 0px;
}

.box {
  width: auto;
}

.notice {
  padding: 10px;
  border: 1px solid #d0d0d0;
  font-size: 15px;
  margin-bottom: 20px;
}
.notice a {
  color: #1D5157;
}

.jumbotron {
  background-color: #00454d;
  color: white;
  margin: 0px;
  padding: 18px 20px;
}
.jumbotron h4 {
  font-size: 21px;
  margin-bottom: 0px;
}

.form {
  background: #fff;
  max-height: 450px;
  overflow: auto;
  padding: 21px 40px 30px 40px;
}

.form .info {
  font-size: 16px;
  margin-bottom: 11px;
}

.lbl {
  margin-bottom: 10px;
}

.loading {
  width: 100%;
  height: 100%;
  padding-left: 50px;
  background: #fff;
}
.loading .status {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 40px;
  margin-left: -50px;
  text-align: center;
}

.newDoc {
  padding: 3px 12px;
  margin: 0px;
}

.fil {
  display: inline;
  width: 350px;
}

.remove {
  float: right;
  margin-top: -25px;
}

.btn {
  padding: 6px 17px;
  border-radius: 4px;
  color: #1D5157;
  margin-bottom: 0;
}
.btn:hover {
  text-decoration: none;
  color: #1D5157;
}

.btnRegular {
  background-color: #E6EBED;
  border: 1px solid #D5DBDA;
}
.btnRegular:hover {
  background-color: #CFD3D5;
  border: 1px solid #CFD3D5;
}
.btnRegular:active {
  background-color: #B8BCBE;
  border: 1px solid #B8BCBE;
}
.btnRegular:disabled, .btnRegular[disabled] {
  background-color: #F0F3F4;
  border: 1px solid #F0F3F4;
  color: #9CA1A4;
  cursor: not-allowed;
}

.btnOutline {
  background-color: none;
  border: 1px solid 1D5157;
}
.btnOutline:hover {
  background-color: 1D5157;
  border: 1px solid 1D5157;
  color: white;
}
.btnOutline:active {
  background-color: #102F41;
  border: 1px solid #102F41;
  color: #CFD5D9;
}
.btnOutline:disabled, .btnOutline[disabled] {
  background-color: #8A9DA8;
  border: 1px solid #8A9DA8;
  color: white;
  cursor: not-allowed;
}

.btnGreen {
  border: 1px solid rgb(27, 180, 148);
  background-color: rgb(27, 180, 148);
  color: white;
}
.btnGreen:hover {
  background-color: #1D5157;
  color: white;
}
.btnGreen:active {
  background-color: #1D5157;
  border-color: #1D5157;
}
.btnGreen .text {
  color: white;
}
.btnGreen .icon {
  font-size: 20px;
}
.btnGreen .icon path {
  color: white;
}

.listItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 4px;
  align-items: center;
}
.listItem i {
  color: #888;
  margin-right: 14px;
}
.listItem:hover {
  background-color: rgb(230, 235, 237);
  cursor: pointer;
}
.listItem .actionButton {
  border: none;
  background: none;
  height: 28px;
  width: 28px;
  text-align: center;
  border-radius: 50%;
  color: rgb(112, 112, 112);
  border: 1px soilid rgb(230, 235, 237);
}
.listItem .actionButton:hover {
  background-color: #eee;
  color: white;
  cursor: pointer;
}
.listItem .sub {
  color: #a0a0a0;
  font-size: 13px;
}
.listItem .main {
  color: #b0b0b0;
  font-size: 12px;
  margin-left: 7px;
}

.listItem.unClicked:hover {
  background-color: white;
}

.listItem.action {
  margin-top: 15px;
  width: 170px;
  color: white;
  padding: 9px 12px;
  font-size: 14px;
  background-color: #00454d;
}
.listItem.action i {
  color: #fff;
}
.listItem.action:hover {
  background-color: #002030;
}

.input {
  width: 100%;
}

.addButton {
  margin-left: 12px;
}

