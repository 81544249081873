@import "App.module.scss";

.module {
  position: static;
  width: 100%;
  height: 100%;
  min-width: 0px;
  min-height: 0px;
}

.box {
  width: auto;
}

.jumbotron {
  background-color: $primary-color;
  color: white;
  margin: 0px;
  padding: 16px 20px;

  h4 {
    font-size: 19px;
    margin-bottom: 0px;
  }
}

.form {
  background: #fff;
  max-height: 280px;
  padding: 24px 40px 18px 40px;
}

.lbl {
  margin-bottom: 10px;
}

.loading {
  width: 100%;
  height: 100%;
  padding-left: 50px;
  background: #fff;

  .status {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 40px;
    margin-left: -50px;
    text-align: center;
  }
}

.btn {
  padding: 5px 17px;
  border-radius: 4px;
  color: #1D5157;
  margin-bottom: 0;
  margin-top: 5px;

  &:hover {
    text-decoration: none;
    color: #1D5157;
  }
}

.error {
  width: 100%;
  margin-top: 5px;
  background-color: #ffeeee;
  color: #a05050;
  font-size: 14px;
  padding: 10px;
  font-weight: bold;
  @include box-sizing(border-box);
}

.btnGreen {
  border: 1px solid $success-color;
  background-color: $success-color;
  color: white;

  &:hover {
    background-color: #1D5157;
    color: white;
  }

  &:active {
    background-color: #1D5157;
    border-color: #1D5157;
  }

  .text {
    color: white;
  }

  .icon {
    font-size: 20px;

    path {
      color: white;
    }
  }
}

.input {
  width: 100%;
  margin-bottom: 4px;
  padding: 8px 7px;
}