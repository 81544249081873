/*
__Seed builder__
  (Read_only) Builder helper
*/
body {
  color: rgb(112, 112, 112) !important;
}

.title {
  font-family: Gotham, Verdana;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
  color: #303131;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 64px;
  padding: none;
  background-color: white;
}
.header .titleContainer {
  display: flex;
  border: none;
  height: 100%;
}
.header .titleContainer .title {
  margin: auto;
}

.heighthundred {
  height: 100%;
}

.module {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgb(240, 243, 244);
  min-width: 960px;
  min-height: 400px;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.verticalBox {
  display: flex;
  flex-flow: row;
  width: 100%;
}

.verticalBox .strech {
  flex: 1 1 auto;
}

.verticalBox .fixed {
  flex: 0 1 auto;
}

.input {
  width: 100%;
}

.box .row.head {
  flex: 0 1 auto;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .row.content {
  flex: 1 1 auto;
}

.box .row.footer {
  flex: 0 1 40px;
}

.input {
  border: none;
  background-color: rgb(230, 235, 237);
  color: #303131;
  height: 40px;
  padding: 0px 4px;
  margin-right: 12px;
  border-radius: 4px;
  width: 180px;
}

.lbl {
  margin-right: 12px;
}

.list {
  height: 100%;
  color: rgb(156, 161, 164);
}

.title {
  float: left;
  text-align: left;
  font-size: 15.5px;
  color: #00454d;
  margin-left: 0px !important;
}
.title i {
  color: #00454d;
  margin-right: 14px;
}

.titleContainer {
  width: 100%;
}

.activeItem {
  background-color: rgb(230, 235, 237) !important;
  color: #00454d !important;
  border: 1px solid rgb(230, 235, 237) !important;
}

.alertTitle {
  color: #303131;
  line-height: normal;
}

.alertName {
  line-height: normal;
  font-size: 12px;
  color: rgb(112, 112, 112);
}

.rowMessage {
  display: flex;
  justify-content: center;
  justify-items: center;
  text-align: center;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
}

.messageTitle {
  text-transform: uppercase;
  color: rgb(112, 112, 112);
  font-size: 1.5em;
}

.happyFace {
  font-size: 4em;
  color: rgb(156, 161, 164);
}

.btn {
  float: right;
  border: 1px solid #d0d0d0;
  margin-top: 2px;
  height: 35px;
  padding: 5px 10px;
  color: #777;
  font-size: 15.5px;
  cursor: pointer;
}
.btn i {
  color: #808080;
  margin-right: 6px;
}
.btn:hover {
  border: 1px solid #bbb;
  color: #888;
  background: #f0f0f0;
}

