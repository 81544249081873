/*
__Seed builder__
  (Read_only) Builder helper
*/
.module {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #00454d;
  overflow: auto;
}

.container {
  position: absolute;
  width: 400px;
  height: auto;
  padding: 50px 40px 40px 40px;
  background-color: #fff;
  border: 1px solid #e7e7e7;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  left: 50%;
  margin-left: -200px;
  top: 50%;
  margin-top: -220px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 1.8em;
  margin-bottom: 52px;
}
.container li {
  margin-top: 5px;
  margin-bottom: 10px;
}
.container li b {
  color: #19454E;
}

.dots {
  position: fixed;
  top: 20%;
  left: 20px;
  width: 190px;
  height: 65%;
  background: linear-gradient(90deg, #00454d 23px, transparent 1%) center, linear-gradient(#00454d 23px, transparent 1%) center, rgba(53, 227, 93, 0.6274509804);
  background-size: 26px 26px;
}

@media (max-width: 720px) {
  .dots {
    display: none;
  }
}
.logo {
  position: fixed;
  display: inline-block;
  height: 45px;
  width: auto;
  top: 32px;
  left: 33px;
}

.crz {
  position: fixed;
  display: inline-block;
  height: 34px;
  width: auto;
  top: 82px;
  left: 35px;
}

.imgKeygen {
  display: inline-block;
  margin: 4px;
  width: 220px;
  border: 2px solid #ddd;
}

.imgSecret {
  display: inline-block;
  margin: 4px;
  width: 300px;
  border: 2px solid #ddd;
}

.title {
  width: 100%;
  font-size: 24px;
  margin-bottom: 8px;
  color: #00454d;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

.subtitle {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 1.5em;
}

.login {
  border: 1px solid #a0a0a0;
  padding: 7px 10px;
  float: right;
  color: #777;
}

.footer {
  position: fixed;
  display: block;
  bottom: 0px;
  width: 420px;
  font-size: 14px;
  text-align: center;
  color: rgba(255, 255, 255, 0.4);
  left: 50%;
  margin-left: -210px;
}

.footer a {
  color: rgba(255, 255, 255, 0.4);
}
.footer a:hover {
  color: #fff;
}

@media (max-width: 720px) {
  .logo {
    height: 30px;
    left: 14px;
    top: 20px;
  }
  .crz {
    height: 30px;
    top: 58px;
    left: 17px;
  }
  .container {
    width: 320px;
    padding: 40px 30px 30px 30px;
    left: 50%;
    margin-left: -160px;
    top: 50%;
    margin-top: -200px;
  }
  .imgSecret {
    width: 260px;
  }
  .registry {
    width: 320px;
    margin-top: 180px;
    left: 50%;
    margin-left: -160px;
  }
  .footer {
    width: 320px;
    left: 50%;
    margin-left: -160px;
  }
}

