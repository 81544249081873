@import "App.module.scss";

.module {
  width: 100%;
  height: 100%;
  background-color: #d5e5e7;
}

.home {
  width: 100%;
  padding: 24px $padding-lateral;
  height: 100%;
}

.sidepanel {
  height: 100%;
  padding-right: 21px;
}

.content {
  height: 100%;
}

.loading {
  position: fixed;
  width: 120px;
  height: 120px;
  left: 50%;
  top: 50%;
  margin-top: -60px;
  margin-left: -60px;
}

@media (max-width: 720px) {
  .home {
    overflow: auto;
    padding: 24px 20px;
  }

  .sidepanel {
    margin-bottom: 10px;
  }
}