/*
__Seed builder__
  (Read_only) Builder helper
*/
body {
  color: rgb(112, 112, 112) !important;
}

.title {
  font-family: Gotham, Verdana;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
  color: #303131;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 64px;
  padding: none;
  background-color: white;
}
.header .titleContainer {
  display: flex;
  border: none;
  height: 100%;
}
.header .titleContainer .title {
  margin: auto;
}

.heighthundred {
  height: 100%;
}

.module {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgb(240, 243, 244);
  min-width: 960px;
  min-height: 400px;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.verticalBox {
  display: flex;
  flex-flow: row;
  width: 100%;
}

.verticalBox .strech {
  flex: 1 1 auto;
}

.verticalBox .fixed {
  flex: 0 1 auto;
}

.input {
  width: 100%;
}

.box .row.head {
  flex: 0 1 auto;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .row.content {
  flex: 1 1 auto;
}

.box .row.footer {
  flex: 0 1 40px;
}

.input {
  border: none;
  background-color: rgb(230, 235, 237);
  color: #303131;
  height: 40px;
  padding: 0px 4px;
  margin-right: 12px;
  border-radius: 4px;
  width: 180px;
}

.lbl {
  margin-right: 12px;
}

.container {
  padding: 0px 48px 0px 22px;
  height: 62px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
  color: white;
  background-color: #00454d;
  border-bottom: 1px solid #002030;
}

.dots {
  width: 36px;
  height: 42px;
  margin-left: 15px;
  background: linear-gradient(90deg, #00454d 6px, transparent 1%) center, linear-gradient(#00454d 6px, transparent 1%) center, rgba(53, 227, 93, 0.6274509804);
  background-size: 8px 8px;
}

.searchBar {
  border: none;
  color: #303131;
  height: 32px;
  width: 330px;
  margin-top: 4px;
  box-sizing: border-box;
  margin-right: 20px;
}
.searchBar div,
.searchBar input {
  height: 32px;
  border-right: none;
}
.searchBar div::placeholder,
.searchBar input::placeholder {
  color: #a0a0a0;
  opacity: 1;
}
.searchBar div:-ms-input-placeholder,
.searchBar input:-ms-input-placeholder {
  color: #a0a0a0;
}
.searchBar div::-ms-input-placeholder,
.searchBar input::-ms-input-placeholder {
  color: #a0a0a0;
}
.searchBar .normalizeAppend {
  background-color: white;
  border-left: none;
  color: rgb(156, 161, 164);
}

.info a {
  display: inline-block;
  margin: 2px 7px 0px 5px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 25px;
}
.info a:hover {
  color: white;
}

.logoContainer {
  height: 52px;
  padding-right: 24px;
  margin-left: 12px;
}
.logoContainer .logo {
  display: inline-block;
  height: 24px;
  margin-top: 10px;
  left: 40px;
}
.logoContainer .crz {
  display: inline-block;
  height: 30px;
  margin-top: 5px;
  left: 40px;
}

.titleContainer {
  border-left: 1px solid rgba(255, 255, 255, 0.2509803922);
  height: 42px;
  text-align: center;
  font-weight: 100;
  font-size: 17.5px;
  color: white;
  line-height: 43px;
  padding-left: 24px;
  margin-left: 12px;
  cursor: default;
}

.flexBoxContainer {
  height: 48px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.right {
  display: flex;
  flex-direction: row;
}

.left {
  display: flex;
  flex-direction: row;
}

.notifications {
  font-size: 16px;
  height: 40px;
  width: 40px;
  background-color: rgba(6, 80, 87, 0.6);
  color: rgb(230, 235, 237);
  border-radius: 4px;
  border: none;
}
.notifications:hover {
  background-color: #002030;
  color: rgb(230, 235, 237);
}

.profile {
  margin-left: 6px;
  height: 40px;
  border-radius: 4px;
  padding: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.profile:hover {
  background-color: #002030;
  color: rgb(230, 235, 237);
  cursor: pointer;
}
.profile .username {
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 0.9rem;
}
.profile .username .name {
  font-size: 0.9rem;
}
.profile .username .type {
  font-size: 0.8rem;
}
.profile .image {
  margin-right: 10px;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background-color: rgb(230, 235, 237);
  background-size: cover;
  background-repeat: no-repeat;
  box-sizing: border-box;
  border: 1px solid rgb(230, 235, 237);
}

@media (max-width: 720px) {
  .titleContainer {
    display: none;
  }
}

