@import "App.module.scss";

.module {
  width: 100%;
  height: 100%;
  background-color: #d5e5e7;
}

.home {
  width: 100%;
  padding: 0px $padding-lateral 24px $padding-lateral;
  height: 100%;
}

.sidepanel {
  height: 100%;
  padding-right: 21px;
}

.folders {
  position: absolute;
  top: 0px;
  width: 100%;
  padding-right: 36px;
}

.alerts {
  padding-top: 107px;
  height: 100%;
}

.content {
  height: 100%;
}

.loading {
  position: fixed;
  width: 120px;
  height: 120px;
  left: 50%;
  top: 50%;
  margin-top: -60px;
  margin-left: -60px;
}

.notice {
  width: 100%;
  padding: 12px 75px 12px 48px;
  > div {
    padding: 10px 18px;
    width: 100%;
    color: white;
    > span {
      display: block;
      float: right;
      padding: 2px 18px;
      cursor: pointer;
    }

    &.completed{
      background: #A0D090;
      > span {
        background-color: white;
        color: #A0D090;
      }
    }

    &.processing{
      background: #8FAADC;
      > span {
        background-color: white;
        color: #8FAADC;
      }
    }

    &.error{
      background: #F07060;
      > span {
        background-color: white;
        color: #F07060;
      }
    }

    
  }
}

.notice > div {
 
}

@media (max-width: 720px) {
  .home {
    overflow: auto;
    padding: 24px 20px;
  }

  .sidepanel {
    margin-bottom: 10px;
  }
}