@import "App.module.scss";

$column-one-width: 210px;
$column-two-width: 160px;
$column-three-width: 300px;
$column-four-width: 100px;

.container table tbody {
  overflow: auto;
  width: 100%;
  height: 10px;
}

.container table {
  width: 100%;
  border: 1px solid #D0D0D0;
}

.container thead {
  width: 100%;
  background-color: $lightblue-color;
}

.container th {
  color: $neutral-color;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: normal;
}

.container th,
.container td {
  padding: 8px 17px;

  span {
    padding: 5px 10px;
  }
}

.container td {
  color: $neutral-color;
}

.container td:nth-child(1),
.container th:nth-child(1) {
  min-width: $column-one-width;
  vertical-align: middle;
}

.container td:nth-child(2),
.container th:nth-child(2) {
  min-width: $column-two-width;
  vertical-align: middle;
  color: $neutral-color-dark;
}

.container td:nth-child(3),
.container th:nth-child(3) {
  width: $column-three-width;
  vertical-align: middle;
  text-align: center;
}

.container td:nth-child(4),
.container th:nth-child(4) {
  width: $column-four-width;
}

.container tr {
  display: block;
}

.removeIcon {
  display: inline-block;
  cursor: pointer;
  color: #b0b0b0;
}