@import "App.module.scss";

.module {
  position: static;
  width: 100%;
  height: 100%;
  min-width: 0px;
  min-height: 0px;
}

.box {
  width: auto;
}


.notice {
  padding: 10px;
  border: 1px solid #d0d0d0;
  font-size: 15px;
  margin-bottom: 20px;

  a {
    color: #1D5157;
  }
}


.jumbotron {
  background-color: $primary-color;
  color: white;
  margin: 0px;
  padding: 18px 20px;

  h4 {
    font-size: 21px;
    margin-bottom: 0px;
  }
}

.form {
  background: #fff;
  max-height: 450px;
  overflow: auto;
  padding: 21px 40px 30px 40px;
}

.form .info {
  font-size: 16px;
  margin-bottom: 11px;
}

.lbl {
  margin-bottom: 10px;
}

.loading {
  width: 100%;
  height: 100%;
  padding-left: 50px;
  background: #fff;

  .status {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 40px;
    margin-left: -50px;
    text-align: center;
  }
}

.newDoc {
  padding: 3px 12px;
  margin: 0px;
}

.fil {
  display: inline;
  width: 350px;
}

.remove {
  float: right;
  margin-top: -25px;
}


.btn {
  padding: 6px 17px;
  border-radius: 4px;
  color: #1D5157;
  margin-bottom: 0;

  &:hover {
    text-decoration: none;
    color: #1D5157;
  }
}

.btnRegular {
  background-color: #E6EBED;
  border: 1px solid #D5DBDA;

  &:hover {
    background-color: #CFD3D5;
    border: 1px solid #CFD3D5;
  }

  &:active {
    background-color: #B8BCBE;
    border: 1px solid #B8BCBE;
  }

  &:disabled,
  &[disabled] {
    background-color: #F0F3F4;
    border: 1px solid #F0F3F4;
    color: #9CA1A4;
    cursor: not-allowed;
  }
}

.btnOutline {
  background-color: none;
  border: 1px solid 1D5157;

  &:hover {
    background-color: 1D5157;
    border: 1px solid 1D5157;
    color: white;
  }

  &:active {
    background-color: #102F41;
    border: 1px solid #102F41;
    color: #CFD5D9;
  }

  &:disabled,
  &[disabled] {
    background-color: #8A9DA8;
    border: 1px solid #8A9DA8;
    color: white;
    cursor: not-allowed;
  }
}

.btnGreen {
  border: 1px solid $success-color;
  background-color: $success-color;
  color: white;

  &:hover {
    background-color: #1D5157;
    color: white;
  }

  &:active {
    background-color: #1D5157;
    border-color: #1D5157;
  }

  .text {
    color: white;
  }

  .icon {
    font-size: 20px;

    path {
      color: white;
    }
  }
}

.listItem {
  @include flex(row, space-between);
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 4px;
  align-items: center;

  i {
    color: #888;
    margin-right: 14px;
  }

  &:hover {
    background-color: $lightblue-color;
    cursor: pointer;
  }

  .actionButton {
    border: none;
    background: none;
    height: 28px;
    width: 28px;
    text-align: center;
    border-radius: 50%;
    color: $neutral-color;
    border: 1px soilid $lightblue-color;

    &:hover {
      background-color: #eee;
      color: white;
      cursor: pointer;
    }
  }

  .sub {
    color: #a0a0a0;
    font-size: 13px;
  }

  .main {
    color: #b0b0b0;
    font-size: 12px;
    margin-left: 7px;
  }
}

.listItem.unClicked {

  &:hover {
    background-color: white;
  }
}

.listItem.action {
  margin-top: 15px;
  width: 170px;
  color: white;
  padding: 9px 12px;
  font-size: 14px;
  background-color: $primary-color;

  i {
    color: #fff;
  }

  &:hover {
    background-color: $dark-color;
  }
}

.input {
  width: 100%;
}

.addButton {
  margin-left: 12px;
}