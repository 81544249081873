@import '_base';

.module {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: $primary-color;
  overflow: auto;
}

.module hr {
  margin-top: 30px;
  border: 3px solid #f0f0f0;
}

.container {
  position: absolute;
  width: 620px;
  height: auto;
  padding: 50px 40px 40px 40px;
  line-height: 1.8em;
  margin-bottom: 52px;
  margin-top: 70px;
  @include card-design();
  @include align-horizontal(620px);
  @include box-sizing(border-box);

  li {
    margin-top: 5px;
    margin-bottom: 10px;

    b {
      color: #19454E;
    }
  }
}

.dots{
  position: fixed;
  top: 20%;
  left: 20px;
  width: 190px;
  height: 65%;
  $bg-color: $primary-color;
  $dot-size: 3px;
  $dot-space: 26px;

	background:
		linear-gradient(90deg, $bg-color ($dot-space - $dot-size), transparent 1%) center,
		linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
		$dot-color;
  background-size: $dot-space $dot-space;
}

@media (max-width: 720px) {
  .dots{
    display: none;
  }
}

.logo {
  position: fixed;
  display: inline-block;
  height: 45px;
  width: auto;
  top: 32px;
  left: 33px;
}

.crz {
  position: fixed;
  display: inline-block;
  height: 34px;
  width: auto;
  top: 82px;
  left: 35px;
}

.imgKeygen {
  display: inline-block;
  margin: 4px;
  width: 220px;
  border: 2px solid #ddd;
}

.imgSecret {
  display: inline-block;
  margin: 4px;
  width: 220px;
  border: 2px solid #ddd;
}



.title {
  width: 100%;
  font-size: 24px;
  margin-bottom: 10px;
  color: $primary-color;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

.subtitle {
  color: #90a0a0;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 15px;
  text-transform: uppercase;
}

.login {
  border: 1px solid #a0a0a0;
  padding: 7px 10px;
  float: right;
  color: #777;
}


.emailLbl,
.passwordLbl {
  font-size: 13px;
  margin-top: 12px;
  margin-bottom: 2px;
  text-transform: uppercase;
  color: #777;
}

.email,
.password {
  width: 100%;
  font-size: 15px;
  padding: 4px 8px;
  border: 1px solid #d0d0d0;
}

.partner {
  background: #fff;
  width: 100%;
  height: 34px;
  padding: 5px;
  border: 1px solid #d0d0d0;
}

.error {
  width: 100%;
  margin-top: 5px;
  background-color: #ffeeee;
  color: #a05050;
  font-size: 14px;
  padding: 10px;
  font-weight: bold;
  @include box-sizing(border-box);
}

.submit {
  margin-top: 21px;
  width: 100%;
  background: $accent-color;
  color: #fff;
  border: none;
  padding: 11px 5px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  @include border-radius(5px)
}


.footer {
  position: absolute;
  display: block;
  top: 1100px;
  left: 50%;
  width: 420px;
  font-family: Arial;
  font-size: 13px;
  text-align: center;
  margin-left: -210px;
  color: rgba(255, 255, 255, 0.4);
}

.footer a {
  color: rgba(255, 255, 255, 0.4);

  &:hover {
    color: #fff;
  }
}


@media (max-width: 720px) {
  .logo {
    height: 30px;
    left: 14px;
    top: 20px;
  }

  .crz {
    height: 30px;
    top: 58px;
    left: 17px;
  }

  .container {
    width: 320px;
    padding: 40px 30px 30px 30px;
    margin-top: 100px;
    @include align-horizontal(320px);
  }

  .registry {
    width: 320px;
    margin-top: 180px;
    @include align-horizontal(320px);
  }

  .footer {
    width: 320px;
    top: 1150px;
    @include align-horizontal(320px);
  }

}