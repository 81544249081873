/*
__Seed builder__
  (Read_only) Builder helper
*/
.module {
  width: 100%;
  height: 100%;
}

.background {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #00454d;
}

.loading {
  position: absolute;
  left: 150px;
  top: 80px;
}

.container {
  position: fixed;
  width: 390px;
  height: auto;
  padding: 50px 36px 40px 36px;
  background-color: #fff;
  border: 1px solid #e7e7e7;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  left: 50%;
  margin-left: -195px;
  top: 50%;
  margin-top: -175px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.container li {
  margin-top: 5px;
  margin-bottom: 10px;
}
.container li b {
  color: #19454E;
}

.dots {
  position: fixed;
  top: 110px;
  left: 20px;
  width: 190px;
  height: 100%;
  background: linear-gradient(90deg, #00454d 23px, transparent 1%) center, linear-gradient(#00454d 23px, transparent 1%) center, rgba(53, 227, 93, 0.6274509804);
  background-size: 26px 26px;
}

@media (max-width: 720px) {
  .dots {
    display: none;
  }
}
.dots {
  position: fixed;
  top: 20%;
  left: 20px;
  width: 190px;
  height: 65%;
  background: linear-gradient(90deg, #00454d 23px, transparent 1%) center, linear-gradient(#00454d 23px, transparent 1%) center, rgba(53, 227, 93, 0.6274509804);
  background-size: 26px 26px;
}

@media (max-width: 720px) {
  .dots {
    display: none;
  }
}
.logo {
  position: fixed;
  display: inline-block;
  height: 45px;
  width: auto;
  top: 32px;
  left: 33px;
}

.crz {
  position: fixed;
  display: inline-block;
  height: 34px;
  width: auto;
  top: 82px;
  left: 35px;
}

.title {
  width: 100%;
  font-size: 22px;
  margin-bottom: 17px;
  color: #00454d;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

.subtitle {
  color: #808080;
  margin-top: 14px;
  margin-bottom: 10px;
  font-size: 16px;
  text-align: left;
}
.subtitle i {
  color: #999;
  font-size: 14px;
  display: inline-block;
  padding: 4px 0px;
}

.warning {
  border: 1px solid #fafafa;
  background: #fafafa;
  color: #00454d;
  border: 1px solid #e0e0e0;
  padding: 12px;
  font-size: 15px;
}

.error {
  width: 100%;
  margin-top: 5px;
  background-color: #ffeeee;
  color: #a05050;
  font-size: 14px;
  padding: 10px;
  font-weight: bold;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.submit {
  margin-top: 10px;
  width: 100%;
  background: #e0c010;
  color: #fff;
  border: none;
  padding: 11px 5px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}

.login {
  display: inline-block;
  margin-top: 10px;
  width: 100%;
  border: 1px solid #a0a0a0;
  padding: 7px 10px;
  color: #777;
  text-align: center;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}
.login:hover {
  background: #f0f0f0;
  text-decoration: none;
}

.footer {
  position: fixed;
  display: block;
  bottom: 0px;
  left: 50%;
  width: 420px;
  font-family: Arial;
  font-size: 13px;
  text-align: center;
  margin-left: -210px;
  color: rgba(255, 255, 255, 0.4);
}

.footer a {
  color: rgba(255, 255, 255, 0.4);
}
.footer a:hover {
  color: #fff;
}

.input {
  margin-top: 5px;
}

@media (max-width: 720px) {
  .logo {
    height: 30px;
    left: 14px;
    top: 20px;
  }
  .crz {
    height: 30px;
    top: 58px;
    left: 17px;
  }
  .container {
    width: 320px;
    padding: 40px 30px 30px 30px;
    left: 50%;
    margin-left: -160px;
    top: 50%;
    margin-top: -180px;
  }
  .registry {
    width: 320px;
    margin-top: 180px;
    left: 50%;
    margin-left: -160px;
  }
  .loading {
    left: 120px;
  }
  .footer {
    width: 320px;
    left: 50%;
    margin-left: -160px;
  }
}

