@import '_base';

.module {
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 45px;
  overflow: auto;
}

.container {
  height: 310px;
  overflow: auto;
  padding: 14px;
  @include card-design();
  @include box-sizing(border-box);
  line-height: 1.8em;
  font-size: 13px;

  li {
    margin-top: 5px;
    margin-bottom: 10px;

    b {
      color: #19454E;
    }
  }
}

.title {
  width: 100%;
  font-size: 16px;
  margin-bottom: 8px;
  color: $primary-color;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

.subtitle {
  color: #90a0a0;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 15px;
  text-transform: uppercase;
}

.checkContainer {
  padding: 14px;
  margin-top: 7px;
  color: #333;

  input {
    margin-right: 10px;
  }
}

.submit {
  margin-top: 7px;
  width: 100%;
  background: $primary-color;
  color: #fff;
  border: none;
  padding: 11px 5px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  @include border-radius(5px)
}