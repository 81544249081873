@import "App.module.scss";

.module {
  position: absolute;
  width: 100%;
  height: 100%;
  min-width: 0px;
  min-height: 0px;
}

.jumbotron {
  width: 100%;
  padding: 25px 25px;
  background: $primary-color;
  color: white;

  h4 {
      color: white;
      margin-bottom: 0px;
      font-size: 21px;
  }
}

.paper {
  height: 100px;
  background: white;
  padding: 20px;
}

.loading{
  background: white;
  height: 100%;
}

.message {
  padding: 7px 0px;
}