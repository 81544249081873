@import '_base';

.module {
  width: 100%;
  height: 100%;
}

.module select {
  background: white;
  border: 1px solid #d0d0d0;
  width: 300px;
  height: 35px;
}

.notice {
  padding: 10px;
  border: 1px solid #d0d0d0;
  font-size: 15px;
  margin-bottom: 20px;

  a {
    color: #1D70A0;
  }
}


.jumbotron {
  background-color: $primary-color;
  color: white;
  margin: 0px;
  padding: 15px 20px;

  h4 {
    font-size: 21px;
    margin-bottom: 0px;
  }
}

.download {
  display: inline-block;
  padding: 2px 7px;
  color: #1080B0;
  border: 1px solid #1080B0;
  margin-bottom: 10px;
}

.form {
  background: #fff;
  max-height: 500px;
  overflow: auto;
  padding: 24px 40px 30px 40px;
}

.copied {
  display: inline-block;
  width: 100%;
  margin-top: 7px;
  background-color: #eeffee;
  color: #50a050;
  font-size: 14px;
  padding: 10px;
  font-weight: bold;
  @include box-sizing(border-box);
}

.lbl {
  margin-bottom: 10px;
}

.loading {
  width: 100%;
  height: 100%;
  padding-left: 50px;
  padding-bottom: 20px;
  background: #fff;

  .status {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    padding-top: 15px;
    margin-top: 40px;
    margin-left: -150px;
    text-align: center;
  }
}

.newDoc {
  padding: 3px 12px;
  margin: 0px;
}

.fil {
  display: inline-block;
  width: 100%;
  padding: 5px 10px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.remove {
  float: right;
  margin-top: -25px;
}


.btn {
  padding: 6px 17px;
  border-radius: 4px;
  color: #1D5157;
  margin-bottom: 0;

  &:hover {
    text-decoration: none;
    color: #1D5157;
  }
}

.btnRegular {
  background-color: #E6EBED;
  border: 1px solid #D5DBDA;

  &:hover {
    background-color: #CFD3D5;
    border: 1px solid #CFD3D5;
  }

  &:active {
    background-color: #B8BCBE;
    border: 1px solid #B8BCBE;
  }

  &:disabled,
  &[disabled] {
    background-color: #F0F3F4;
    border: 1px solid #F0F3F4;
    color: #9CA1A4;
    cursor: not-allowed;
  }
}


.btnGreen {
  border: 1px solid $corpor-color;
  background-color: $corpor-color;
  margin-top: 5px;
  color: white;
  display: inline-block;
  padding: 6px 15px !important;

  &:hover {
    background-color: $dark-color;
    color: white;
  }

  &:active {
    background-color: $primary-color;
    border-color: $primary-color;
  }

  .text {
    color: white;
  }

  .icon {
    font-size: 20px;

    path {
      color: white;
    }
  }
}
a.btnGreen{
  color: white !important;
  text-decoration: none !important;
}

.preview {
  margin-top: 20px;
  margin-bottom: 20px;
}

.sub {
  font-weight: bold;
  color: $primary-color;
}